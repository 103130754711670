import createAsyncSlice from './helper/createAsyncSlice';
import { API_URL } from '../config/App';
var marks = [];
let photos = createAsyncSlice({
  name: 'photos',
  initialState: {
    list: [],
    mark: [],
    user: 0,
    pages: 1,
    category: 0,
    favorite: 0,
    infinite: true,
  },
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
    },
    clearPhotos(state, action) {
      state.list = [];
    },
    addPage(state) {
      state.pages++;
    },    
    setCategory(state,cat) {
      state.category = cat.payload;
    },
    setFavorito(state,fav) {
      state.favorite = fav.payload;
    },
    showMarketing(state) {
      if (marks.length > 0) {
        let markx = marks[Math.floor(Math.random() * marks.length)];
        markx = {...markx, id: Math.floor(Math.random() * 100000) };
        state.list.push(markx);
      }
    },        
    addMarketing(state, action) {
      marks.push(...action.payload.data);
    },        
    addPhotos(state, action) {
      state.list.push(...action.payload.data);
      //state.pages++;
      if (action.payload.meta.current_page === action.payload.meta.last_page) state.infinite = false;
    },
    removePhotos(state) {
      state.pages = 1;
      state.infinite = true;
      state.list = [];
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    resetState(state) {
      state.infinite = true;
      state.pages = 1;
      state.list = [];
      state.data = null;
      state.error = null;
      state.loading = false;
    },
    searchbyTitle(state, action) {

      state.list.push(...action.payload.payload.data);

      //const data = action.payload.payload.data;
      //const overFiveKg = data?.filter(({ title }) => title.toUpperCase().indexOf(action.payload.chave.toUpperCase()) > -1);
      //const transformPound = overFiveKg?.map((photo) => ({
      //  ...photo,
      //  peso: Math.floor(photo.peso * 5.2),
      //}));

      //state.list.push(...transformPound);
      state.infinite = false;
    },
  },
  fetchConfig: (data) => ({
    url: `${API_URL}/club/getall?page=${data.page}&total=${data.total}&key=${data.key}&category=${data.category}&user=${data.user}&shared=${data.shared}&area_id=${data.area}`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});

const slice = createAsyncSlice({
  name: 'photos',
  initialState: {
    cache: 5000,
    list: [],
    user: 0,
    pages: 0,
    infinite: true,
  },
  fetchConfig: () => ({
    url:
    `${API_URL}/club/getall?page=1&total=6&user=0`,
    options: {
      method: 'GET',
      cache: 'no-store',
    },
  }),
});


export const {  showMarketing, addMarketing, addPhotos,  addPage, addPhotos2, setCategory, setFavorito, searchbyTitle, setUser, clearPhotos, removePhotos, resetState: resetFeedState } = photos.actions;
export const fetchPhotos = photos.asyncAction;
export const fetchPhotos2 = slice.asyncAction;

export const getOverFiveKg = (state) => {
  const data = state;
  const overFiveKg = data?.filter(({ peso }) => peso >= 0);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 2.2),
  }));
  return transformPound;
};

export const getbyTitle = (state, chave) => {
  console.log(chave);
  const data = state;

  const overFiveKg = data?.filter(({ title }) => title.indexOf(chave) > -1);
  const transformPound = overFiveKg?.map((photo) => ({
    ...photo,
    peso: Math.floor(photo.peso * 5.2),
  }));
  return transformPound;
};

export const loadNewPhotose = (page = 1, user = 0, category = '', key = '', shared = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({page, user, key, shared}));
  dispatch(addPhotos(payload));
};

export const loadNewMarketing = (area = 7, total = 100, page = 1, category = '', user = 0, key = '', shared = 1) => async (
  dispatch,
  getState,
) => {
  const { photos } = getState();
  const { payload } = await dispatch(
    fetchPhotos({ page: photos.pages, total, user, key, category:photos.category, shared, area }),
  );
  dispatch(addMarketing(payload));
};

export const loadNewPhotos = (area = 1, total = 20, page = 1, category = '', user = 0, key = '', shared = 1) => async (
  dispatch,
  getState,
) => {
  const { photos } = getState();
  dispatch(addPage());
  const { payload } = await dispatch(
    fetchPhotos({ page: photos.pages, total, user, key, category:photos.category, shared, area }),
  );
  dispatch(addPhotos(payload));
};

export const loadSnippets = (area = 3, total = 20, page = 1, category = 9, user = 0, key = '', shared = 1) => async (
  dispatch,
  getState,
) => {
  console.log('ENTROU NO SNIPPETS');
  const { photos } = getState();
  dispatch(addPage());
  const { payload } = await dispatch(
    fetchPhotos({ page: photos.pages, category, total, user, key, shared, area }),
  );
  //dispatch();
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInt = (page = 1, category = '', user = 1, key = '', shared = 1) => async (dispatch) => {
  console.log('user',user);
  const { payload } = await dispatch(fetchPhotos({page:page, category: category, user:user, key:key, shared:shared}));
  dispatch(addPhotos(payload));
};

export const searchTitle = (key = '', page = 1, total = 0, category = '', user = 0, shared = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({key:key, page:page, total:total, category:category, user:user, shared:shared}));
  dispatch(removePhotos());
  dispatch(searchbyTitle({payload, key}));
};

export const loadNewPhotosInicial2 = (page = 1, category = '', user = 0, key = '', shared = 1) => async (dispatch) => {
  const { payload } = await dispatch(fetchPhotos({page, category, user, key, shared}));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInicial = (page = 1, category = '', user = 0, key = '', shared = 1) => async (
  dispatch,
  getState,
) => {
  const { photos } = getState();
  dispatch(addPage());
  const { payload } = await dispatch(
    fetchPhotos({ page: photos.pages, category, user, key, shared }),
  );
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export const loadNewPhotosInicialInt = (page = 1, category = '', user = 234, key = '', shared = 1) => async (dispatch) => {
  console.log('user',user);
  const { payload } = await dispatch(fetchPhotos({page:page, category:category, user:user, key:key, shared:shared}));
  dispatch(removePhotos());
  dispatch(addPhotos(payload));
};

export default photos.reducer;
